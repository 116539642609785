import { Location } from 'models/Location'
import { Activity } from './Activity'
import { isNil } from 'lodash'

export class Event {
  public static init(data): Event {
    if (isNil(data)) {
      return new Event()
    }
    const event = new Event()
    event.id = data.id
    event.name = data.name
    event.description = data.description
    event.locationId = data.locationId
    event.activityId = data.activityId
    event.coverPhotographSrc = data.coverPhotographSrc
    event.landingPath = data.landingPath
    event.date = data.date
    event.ownerId = data.ownerId
    event.isFree = data.isFree
    event.isActive = data.isActive
    event.quantityDiscountId = data.quantityDiscountId
    event.participants = data.participants
    event.isFree = data.isFree
    event.accreditationsEnabled = data.accreditationsEnabled
    event.customBannerSrc = data.customBannerSrc
    event.displayInFeed = data.displayInFeed
    event.faceRecognitionFilter = data.faceRecognitionFilter
    event.textFilter = data.textFilter
    event.albumsView = data.albumsView
    event.filtersView = data.filtersView

    if (!isNil(data.defaultImagePrice)) {
      event.defaultImagePrice = data.defaultImagePrice
    }
    if (!isNil(data.defaultPackagePrice)) {
      event.defaultPackagePrice = data.defaultPackagePrice
    }
    if (!isNil(data.commission)) {
      event.commission = data.commission
    }
    if (!isNil(data.currency)) {
      event.currency = data.currency
    }
    if (!isNil(data.activity)) {
      event.activity = data.activity
    }
    if (!isNil(data.location)) {
      event.location = data.location
    }

    return event
  }

  id: string

  name: string

  description: string

  date: Date

  coverPhotographSrc: string

  landingPath: string

  ownerId: string

  locationId: string

  location: Location

  activityId: string

  currency?: string | null

  commission?: number | null

  defaultImagePrice?: number | null

  defaultPackagePrice?: number | null

  quantityDiscountId?: string | null

  activity: Activity

  isActive: boolean

  isFree: boolean

  participants?: number | null

  accreditationsEnabled: boolean

  displayInFeed: boolean

  faceRecognitionFilter: boolean

  textFilter: boolean

  albumsView: boolean

  filtersView: boolean

  customBannerSrc?: string | null

  deletedAt?: Date | null

  createdAt?: Date | null

  updatedAt?: Date | null
}
